/* eslint-disable no-console */
import { retry, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { onHandleErrorResponse } from './error-handler-util';

// Function to dynamically determine the base URL
export const getBaseUrl = (agentModel) => {
  if (agentModel === 'Text to Query') {
    return process.env.REACT_APP_AI_ASSISTANT_QUERY_BASE_URL;
  }
  return process.env.REACT_APP_AI_ASSISTANT_BASE_URL;
};

// Dynamic baseQuery function
const dynamicBaseQuery = ({ agentModel }) => fetchBaseQuery({
  baseUrl: getBaseUrl(agentModel),
});

export const aiAssistantApi = createApi({
  reducerPath: 'aiAssistantApi',
  baseQuery: retry(async (args, api, extraOptions) => {
    const { agentModel, ...rest } = args;
    const baseQuery = dynamicBaseQuery({ agentModel });
    const result = await baseQuery(rest, api, extraOptions);
    if (result.error) {
      // handle rety request mechanism
      onHandleErrorResponse({ retry, result, dispatch: api.dispatch });
    }
    return result;
  }),
  endpoints: (builder) => ({
    postFeedbacks: builder.mutation({
      query: ({ payload }) => ({
        url: '/v1/feedbacks',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['feedbacks'],
    }),
  }),
});

// Helper function to process the content from the server
export const getContent = (chunk) => {
  const lines = chunk.split('\n');
  const dataLines = lines
    .filter((line) => line.startsWith('data:'));
  const replaceDataLines = dataLines
    .map((line) => {
      try {
        const trimLine = line.replace('data: ', '').trim();
        return trimLine;
      } catch {
        console.log('error line-->', line);
        return '';
      }
    });
  const contents = replaceDataLines.map((line) => {
    try {
      const json = JSON.parse(line);
      return json.content;
    } catch (e) {
      console.log('JSON.parse ERROR', e);
      return line;
    }
  });
  return contents.join('');
};

export const postQuery = (
  query,
  threadId,
  languageModel,
  agentModel,
  company,
  csn,
  onStreamCallBack,
  onDataCallback,
  setController,
) => {
  const ctlr = new AbortController();
  setController(ctlr);
  const { signal } = ctlr;

  const requestUrl = `${getBaseUrl(agentModel)}/v1/conversations`;

  const payload = {
    query,
    id: threadId,
    model: languageModel,
  };

  if (company) {
    payload.company = {
      name: company?.name,
      env: company?.env,
      csn,
      isAgency: company?.isAgency ?? false,
      isAgencyNxM: company?.isAgencyNxM ?? false,
      partnerType: company?.partnerType ?? '',
      salesOrg: company?.salesOrg ?? '',
    };
  }

  const req = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/event-stream',
    },
    body: JSON.stringify(payload),
    signal,
  };

  fetch(requestUrl, req)
    .then((response) => {
      // Extract the `x-adsk-message-id` header
      const messageId = response.headers.get('x-adsk-message-id');

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let chunkMessage = '';
      const read = () => {
        reader.read().then(({ done, value }) => {
          if (done) {
            onStreamCallBack({ chunkMessage, messageId });
            return;
          }
          const chunk = decoder.decode(value, { stream: true });
          const content = getContent(chunk);
          chunkMessage += content;
          onDataCallback(content);
          read();
        }).catch((error) => {
          console.log('error-->', error);
          if (error.name === 'AbortError') {
            console.log('Aborted');
            onStreamCallBack({ chunkMessage, messageId });
          } else {
            console.log('read error', error);
            onStreamCallBack({
              chunkMessage: `${chunkMessage} !!read error!! ${error}`,
              messageId,
            });
          }
        });
      };
      read();
    })
    .catch((error) => {
      console.log('sendQueryToServer: error', error);
      onStreamCallBack({
        errorMessage: `!!read error!! ${error}`,
      });
    });
};

export const {
  usePostFeedbacksMutation,
} = aiAssistantApi;
