import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';

// components
import AIAssistantComponent from '@pws-dev-portal/components/src/AIAssistant';

import {
  selectCompany,
} from '../features/user/user-slice';

// services
import {
  postQuery,
  usePostFeedbacksMutation,
} from '../services/ai-assistant';

function AIAssistant() {
  const featureFlags = useFlags();

  // selectors
  const selectedCompany = useSelector(selectCompany);

  // service
  const [postFeedbacks] = usePostFeedbacksMutation();

  const displayAgents = featureFlags?.displayAiAssistantAgents;

  return (
    <>
      {featureFlags?.aiPartnerAssistant && (
        <AIAssistantComponent
          postQuery={postQuery}
          postFeedbacks={postFeedbacks}
          displayAgents={displayAgents}
          selectedCompany={selectedCompany}
        />
      )}
    </>
  );
}

export default AIAssistant;
