// AIAssistant.js
import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';

import Tooltip from '@mui/material/Tooltip';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import questions from './data/questions.json';

// components
import Chips from '../Chips';
import Footer from './components/Footer';
import Header from './components/Header';
import TextInput from './components/TextInput';
import MessageArea from './components/MessageArea';
import AnimatedText from './components/AnimatedText';
import FeedbackDialog from './components/FeedbackDialog';
import DropdownSelector from './components/DropdownSelector';

import {
  ChatIcon,
  ChatContainer,
  IconContainer,
  ChatboxContainer,
  ScrollDownButton,
  MsgAreaContainer,
  QuestionStarterContainer,
} from './styles';

import constants from './constants';

const languageModel = 'gpt-4';

const agents = [
  { title: 'General Knowledge' },
  { title: 'Text to Query' },
];

export const AIAssistant = ({
  postQuery,
  postFeedbacks,
  displayAgents,
  selectedCompany,
}) => {
  const textRef = React.createRef();
  const messageContainerRef = useRef(null);
  const messagesEndRef = useRef(null);
  const parentRef = useRef(null);

  // content states
  const [messages, setMessages] = useState([]);
  const [streamMessage, setStreamMessage] = useState('');
  const [selectedFeedbackMsg, setSelectedFeedbackMsg] = useState(null);
  const [showThankyouMsg, toggleThankYouMsg] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(agents[0].title);
  const [selectedCSN, setSelectedCSN] = useState(null);
  const [listOfCSN, setListOfCSN] = useState([]);

  // view states
  const [loading, setLoading] = useState(false);
  const [chatopen, setChatopen] = useState(false);
  const [isFullView, setFullView] = useState(false);
  const [showMessageIcon, setShowMessagIcon] = useState(true);
  const [isScrollAvailable, setIsScrollAvailable] = useState(false);
  const [showFeedbackDialog, toggleFeedbackDialog] = useState(false);

  const [tooltipText, setTooltipText] = useState('Copy response');
  const [timerId, setTimerId] = useState(null);
  const [threadId, setThreadId] = useState(null);

  // AbortController
  const [controller, setController] = useState(null);

  const toggle = () => {
    setChatopen(!chatopen);
  };

  const checkScrollAvailability = () => {
    const container = messageContainerRef.current;
    if (container) {
      const hasMoreScroll = container.scrollHeight > container.clientHeight;
      const isScrolledToBottom = container
        .scrollHeight - container.scrollTop - 20 <= container.clientHeight;

      setIsScrollAvailable(hasMoreScroll && !isScrolledToBottom);
    }
  };

  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container && container.scrollTo) {
      container.scrollTo({
        top: container.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (threadId === null) {
      setThreadId(uuidv4());
    }
  }, [messages, threadId]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const container = messageContainerRef.current;

    // Check scroll availability initially
    checkScrollAvailability();

    if (container) {
      // Add scroll event listener
      container.addEventListener('scroll', checkScrollAvailability);

      // Cleanup on unmount
      return () => {
        container.removeEventListener('scroll', checkScrollAvailability);
      };
    }
  }, [messages]);

  useEffect(() => {
    if (loading) {
      scrollToBottom();
    }
  }, [loading]);

  useEffect(() => {
    if (isEmpty(selectedCompany)) {
      setSelectedAgent('General Knowledge');
    } else {
      const csnArr = selectedCompany?.csn.split(',');
      setListOfCSN([...csnArr]);

      // update csn if not empty
      if (csnArr && csnArr.length > 0) {
        setSelectedCSN(csnArr[0]);
      } else {
        setSelectedCSN([]);
      }
    }
  }, [selectedCompany]);

  const onHandleDisplayPostFeedbackMessage = ({ shouldDisplay }) => {
    toggleThankYouMsg(shouldDisplay);
  };

  const onHandleSubmitFeedback = async ({
    rating,
    comment,
    messageId,
    conversationId,
    shouldDisplayThankyouMsg,
  }) => {
    onHandleDisplayPostFeedbackMessage({ shouldDisplay: false });
    try {
      let payload = {
        rating,
        conversationId,
        messageId,
      };

      // add comment payload if not empty
      if (!isEmpty(comment)) {
        payload = {
          ...payload,
          comment,
        };
      }

      await postFeedbacks({ payload }).unwrap();

      if (shouldDisplayThankyouMsg) {
        onHandleDisplayPostFeedbackMessage({ shouldDisplay: true });
      }
    } catch (err) {
      // silent error
      // @TODO for enhancement if support needed
    }
  };

  const onHandleSend = async (text) => {
    if (loading || text === '') return;

    const query = text || textRef.current.value;

    if (query !== '') {
      const userMessage = {
        text: query,
        sender: 'user',
        type: 'text',
        timeStamp: moment(new Date()).format('hh:mm a'),
        id: uuidv4(),
      };

      // append initial bot message to display stream message
      const botMessage = {
        text: '',
        sender: 'bot',
        type: 'markdown',
        id: uuidv4(),
      };

      toggleThankYouMsg(false);

      // add user message
      setMessages([...messages, userMessage, botMessage]);

      setLoading(true);

      // clear chat message
      textRef.current.value = '';

      setStreamMessage('');

      const onStreamCallBack = ({ chunkMessage, messageId }) => {
        setLoading(false);
        const botActualMessage = {
          ...botMessage,
          text: chunkMessage,
          sender: 'bot',
          type: 'markdown',
          timeStamp: moment(new Date()).format('hh:mm a'),
          messageId,
        };
        setMessages([...messages, userMessage, botActualMessage]);
        checkScrollAvailability();
      };

      postQuery(
        query,
        threadId,
        languageModel,
        selectedAgent,
        selectedCompany,
        selectedCSN,
        onStreamCallBack,
        (newData) => {
          setStreamMessage((prevData) => prevData + newData);
          checkScrollAvailability();
        }, setController,
      );
    }
  };

  // Agents dropdown options
  const agentsOpts = useMemo(() => agents?.map((agent) => agent.title), [agents]);
  const csnOpts = useMemo(() => listOfCSN?.map((csn) => csn), [listOfCSN]);

  const onHandleCopyClick = (text) => {
    navigator.clipboard.writeText(text);

    // Update tooltip text
    setTooltipText('Copied!');

    // Clear previous timer if it exists
    if (timerId) {
      clearTimeout(timerId);
    }

    // Revert text back after 3 seconds
    const newTimerId = setTimeout(() => {
      setTooltipText('Copy response');
    }, 1000);

    setTimerId(newTimerId);
  };

  const onHandleShowFeedbackDialog = ({
    message,
    displayDialog,
  }) => {
    toggleFeedbackDialog(displayDialog);
    setSelectedFeedbackMsg(message);
  };

  const onAbortClick = () => {
    if (controller) {
      controller.abort();
    }
  };

  const onHandleRefesh = () => {
    if (!loading) {
      setMessages([]);
      setThreadId(uuidv4());
      setSelectedFeedbackMsg(null);
    }
    toggleThankYouMsg(false);
  };

  const onHandleFullView = () => {
    setFullView(!isFullView);
  };

  const onHandleCloseChat = () => {
    toggle();
    setShowMessagIcon(true);
    setFullView(false);
  };

  const onHandleUpdateFeedback = ({ message, rating, comment }) => {
    // find index to update
    const index = messages.findIndex((m) => m.id === message.id);

    if (index !== -1) {
      messages[index] = { ...messages[index], rating };

      onHandleSubmitFeedback({
        rating,
        comment,
        messageId: messages[index].messageId,
        conversationId: threadId,
        shouldDisplayThankyouMsg: rating && rating === constants.FEEDBACK_RATINGS.THUMBS_DOWN,
      });

      setMessages(messages);
    }
  };

  const onHandleCancelFeeback = () => {
    toggleFeedbackDialog(false);

    // send feedback without comment
    onHandleUpdateFeedback({
      message: selectedFeedbackMsg,
      rating: selectedFeedbackMsg.rating,
    });

    setSelectedFeedbackMsg(null);
  };

  const onHandleSendFeedback = (text) => {
    toggleFeedbackDialog(false);
    scrollToBottom();

    onHandleUpdateFeedback({
      message: selectedFeedbackMsg,
      rating: selectedFeedbackMsg.rating,
      comment: text,
    });

    setSelectedFeedbackMsg(null);
  };

  const renderSampleQuestionChips = ({ text }) => (
    <div
      style={{
        cursor: loading ? 'default' : 'pointer',
        opacity: loading ? '50%' : '100%',
        marginTop: 12,
      }}
      onClick={() => onHandleSend(text)}
    >
      <Chips message={text} bgColor='darkseagreen' color='white' />
    </div>
  );

  const renderQuestion = () => {
    if (selectedAgent === 'General Knowledge') {
      return questions['general-knowledge'].map((q) => (
        renderSampleQuestionChips({ text: q })
      ));
    }

    if (selectedAgent === 'Text to Query') {
      return questions['text-to-query'].map((q) => (
        renderSampleQuestionChips({ text: q })
      ));
    }
    return null;
  };

  return (
    <ChatContainer isFullView={isFullView} isOpen={chatopen} ref={parentRef} >
      <ChatboxContainer isFullView={isFullView} isOpen={chatopen} >
        <Header
          loading={loading}
          isFullView={isFullView}
          onHandleRefesh={onHandleRefesh}
          onHandleFullView={onHandleFullView}
          onHandleCloseChat={onHandleCloseChat}
        />

        {displayAgents && selectedCompany && (
          <div style={{ padding: 8 }}>
            <DropdownSelector
              isFullView={isFullView}
              selectOpts={agentsOpts}
              selectedOption={selectedAgent}
              onHandleDropdownChange={(agent) => {
                setSelectedAgent(agent);
              }}
            />

            {(selectedAgent === 'Text to Query'
              && listOfCSN
              && listOfCSN.length > 1)
              && (
                <>
                  <div style={{ marginTop: 8 }} />
                  <DropdownSelector
                    isFullView={isFullView}
                    selectOpts={csnOpts}
                    selectedOption={selectedCSN}
                    onHandleDropdownChange={(agent) => {
                      setSelectedCSN(agent);
                    }}
                    icon='assignment'
                  />
                </>
              )}
          </div>
        )}

        <MsgAreaContainer
          isFullView={isFullView}
          ref={messageContainerRef}
        >
          <div style={{ background: 'white', padding: 12, borderRadius: 12 }}>
            <span>
              Hi there! If you have any questions, I&apos;m here to assist you.
            </span>
          </div>

          <div
            style={{
              background: 'white',
              padding: 12,
              borderRadius: 12,
              marginTop: 12,
            }}
          >
            <span>
              Here are a few helpful things you can check out to get started:
            </span>
          </div>

          <QuestionStarterContainer>
            {renderQuestion()}
          </QuestionStarterContainer>

          <MessageArea
            messages={messages}
            loading={loading}
            streamMessage={streamMessage}
            handleCopyClick={onHandleCopyClick}
            handleUpdateFeedback={onHandleUpdateFeedback}
            tooltipText={tooltipText}
            updateFeedback={onHandleUpdateFeedback}
            showFeedbackDialog={onHandleShowFeedbackDialog}
            parentRef={messageContainerRef}
          />

          <div ref={messagesEndRef} />

          {showThankyouMsg && (
            <div style={{ marginTop: 16 }}>
              <AnimatedText
                text='Thanks for your feedback!'
                duration={5000}
                onHide={() => toggleThankYouMsg(false)}
              />
            </div>
          )}

          {isScrollAvailable && (
            <ScrollDownButton onClick={scrollToBottom} isFullView={isFullView} >
              <ArrowDownwardIcon
                style={{ color: 'white', fontSize: 24 }}
                alt="chat-icon"
              />
            </ScrollDownButton>
          )}
        </MsgAreaContainer>

        <TextInput
          textRef={textRef}
          loading={loading}
          handleSend={onHandleSend}
          onAbortClick={onAbortClick}
          inputName='query'
        />
        <Footer />
      </ChatboxContainer>

      {
        showMessageIcon && (
          <Tooltip placement="bottom-end" title={'Chat with me!'}>
            <IconContainer
              onClick={() => {
                setShowMessagIcon(false);
                setChatopen(true);
              }}
              data-testid="chatbot-button"
            >
              <ChatIcon>
                <QuestionAnswerIcon
                  style={{ color: 'white', fontSize: 32 }}
                  alt="chat-icon"
                />
              </ChatIcon>
            </IconContainer>
          </Tooltip>
        )
      }

      <FeedbackDialog
        open={showFeedbackDialog}
        onCancel={onHandleCancelFeeback}
        onConfirm={onHandleSendFeedback}
      />
    </ChatContainer >
  );
};

AIAssistant.propTypes = {
  postQuery: PropTypes.func.isRequired,
  postFeedbacks: PropTypes.func.isRequired,
  displayAgents: PropTypes.bool.isRequired,
  selectedCompany: PropTypes.object.isRequired,
};

export default AIAssistant;
